<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      订单>农服务订单
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 左边查询区 -->
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">订单号：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.orderNo"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">支付方式：</label>
            <el-select size="mini" style="width:100px;float: left;" v-model="selectForm.isPayOnline" clearable
                       filterable>
              <el-option v-for="item in isOnlineList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>


          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary"
                       @click="pageMerchantOrder(true)">搜索
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="selectReset()">重置
            </el-button>
          </div>

        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" @selection-change="handleSelectionChange" height="600" :data="tableData"
                    style="color: #555;" size="mini">
            <el-table-column sortable align="center" prop="orderNo" label="订单号" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="no" label="买家" min-width="20%">
            </el-table-column>
            <el-table-column align="center" prop="productMoney" label="商品价格" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="payNumber" label="购买数量" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="payMoney" label="用户支付" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="isPayOnlineStr" label="支付方式" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="isPayStr" label="已支付" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="isOnlineStr" label="渠道" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="statusStr" label="订单状态" min-width="20%"></el-table-column>
            <el-table-column align="center" label="商户" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.merchantVO && scope.row.merchantVO.merchantDetailVO ? scope.row.merchantVO.merchantDetailVO.companyName : ""
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="productName" label="产品" min-width="20%">
            </el-table-column>
            <el-table-column align="center" label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate ? scope.row.createDate : "") }}
              </template>
            </el-table-column>

            <el-table-column align="center" label="总跟踪单" min-width="20%">
              <template slot-scope="scope">
               <span style="color:#25AD7F;text-decoration:underline;cursor: pointer"> {{ scope.row.workflowVO&&scope.row.workflowVO.workflowNo?scope.row.workflowVO.workflowNo:'' }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作" min-width="40%">
              <template slot-scope="scope">
                 <span v-if="!(scope.row.workflowVO&&scope.row.workflowVO.workflowNo)" style="font-size:0.9rem;color:#25AD7F;cursor: pointer;"
                       @click="insertWorkflowShow(scope.row)">创建总跟踪单</span>
                <span v-if="!(scope.row.workflowVO&&scope.row.workflowVO.workflowNo)" style="margin:0px 5px">|</span>
                <span style="font-size:0.9rem;color:#25AD7F;cursor: pointer;"
                      @click="detailShow(scope.row)">详情</span>
                <span style="margin:0px 5px">|</span>

                <span v-if="scope.row.status=='complete'"
                      style="font-size:0.9rem;cursor: pointer;color: #409EFF" @click="insertOrderSettlement(scope.row)">申请提现</span>
                <span v-if="scope.row.status!='complete'"
                      style="font-size:0.9rem;cursor: pointer;color: gray;cursor: not-allowed;">申请提现</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 详情 -->
    <el-dialog title="订单详情" :visible.sync="detailDialog" width="40%" :before-close="detailCancel">
      <div style="width: 100%;float: left">
        <div
          style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"
          v-if="detailInfo">
          <el-descriptions title="订单" :column=2>
            <el-descriptions-item label="订单号">{{ detailInfo.orderNo ? detailInfo.orderNo : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="渠道">{{ detailInfo.isOnlineStr ? detailInfo.isOnlineStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="订单状态">{{ detailInfo.statusStr ? detailInfo.statusStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="支付方式">{{ detailInfo.isPayOnlineStr ? detailInfo.isPayOnlineStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="是否支付">{{ detailInfo.isPayStr ? detailInfo.isPayStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="购买数量">{{ detailInfo.payNumber ? detailInfo.payNumber : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="支付金额">{{ detailInfo.payMoney ? detailInfo.payMoney : "" }}元
            </el-descriptions-item>
            <el-descriptions-item label="创建时间" :span="2">
              {{ parseDate(detailInfo.createDate ? detailInfo.createDate : "")
              }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="微信用户" :column=2>
            <el-descriptions-item label="账号">{{ detailInfo.userVO ? detailInfo.userVO.no : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="名称">{{ detailInfo.userVO ? detailInfo.userVO.name : "" }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="订单地址" :column=2>
            <el-descriptions-item label="收件人姓名">
              {{ detailInfo.toOrderAddressVO ? detailInfo.toOrderAddressVO.userName : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="收件人联系方式">
              {{ detailInfo.toOrderAddressVO ? detailInfo.toOrderAddressVO.userTel : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="收件人地址">
              {{ detailInfo.toOrderAddressVO ? detailInfo.toOrderAddressVO.fullAddress : "" }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="产品" :column=2>
            <el-descriptions-item label="类型">
              {{ detailInfo.productVO.goodsTypeStr ? detailInfo.productVO.goodsTypeStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="对应类型">
              {{ detailInfo.productVO.productTypeStr ? detailInfo.productVO.productTypeStr : ""
              }}{{ detailInfo.productVO.workflowTypeStr ? detailInfo.productVO.workflowTypeStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="标题">{{ detailInfo.productVO.title ? detailInfo.productVO.title : "0" }}
            </el-descriptions-item>
            <el-descriptions-item label="价格">{{ detailInfo.productVO.price ? detailInfo.productVO.price : "0" }}元
            </el-descriptions-item>
            <el-descriptions-item label="特色">{{ detailInfo.productVO.feature ? detailInfo.productVO.feature : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="产品性能">{{ detailInfo.productVO.context ? detailInfo.productVO.context : "0"
              }}
            </el-descriptions-item>
            <el-descriptions-item label="创建时间" :span="2">
              {{ parseDate(detailInfo.createDate ? detailInfo.createDate : "")
              }}
            </el-descriptions-item>
            <el-descriptions-item label="缩略图" :span="2">
              <el-image v-if="detailInfo.productVO.mainImageGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+detailInfo.productVO.mainImageGuid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+detailInfo.productVO.mainImageGuid]">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item label="详细照片" :span="2">
              <div style="width: 100%;float: left;" v-if="detailInfo.productVO.detailImageGuid"
                   v-for="guid in detailInfo.productVO.detailImageGuid">
                <el-image title="点击放大"
                          style="width: 100px; height: auto"
                          :src="'/admin/web/file/downloadFile?id='+guid"
                          :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
                </el-image>
                <br />
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="detailCancel">关闭</el-button>
      </div>
    </el-dialog>


    <el-dialog title="创建总跟踪单" :visible.sync="insertWorkflowDialog" width="40%"
               :before-close="insertWorkflowCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">

          <el-form-item style="">
            <template slot="label">
              服务单位：
            </template>
            <div v-if="chooseOrder">
              {{ chooseOrder.merchantVO && chooseOrder.merchantVO.merchantDetailVO ? chooseOrder.merchantVO.merchantDetailVO.companyName : ""
              }}
            </div>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              服务类型：<span style="color: red">*</span>
            </template>
            <el-select size="mini" style="float: left;" v-model="insertWorkflowForm.serviceType" clearable filterable>
              <el-option v-for="item in workflowServiceTypeList" :key="item.value" :label="item.name"
                         :value="item.value">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              客户名称：<span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertWorkflowForm.customName"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              手机号码：<span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertWorkflowForm.customNo"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              区域：<span style="color: red">*</span>
            </template>
            <el-cascader size="large" :options="options" v-model="insertWorkflowForm.customAddressCode"
                         @change="handleChange" placeholder="请选择省市区" style="width:200px;"></el-cascader>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              详细地址：<span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertWorkflowForm.street"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


        </el-form>


      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertWorkflowSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertWorkflowCancel">取消</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>


import { regionData, codeToText } from "element-china-area-data";


import { listIsOnlineApi, listWorkflowServiceTypeApi } from "@/router/api/common/data-api";
import { pageMerchantOrderApi } from "@/router/api/order/order-api";
import { getMyMerchantApi } from "@/router/api/merchant/merchant-api";

import { insertOrderSettlementApi } from "@/router/api/order/order-settlement-api";

import { insertWorkflowApi } from "@/router/api/workflow/workflow-api";

export default {
  name: "MerchantWorkflow",
  data() {
    return {
      merchantInfo: {},
      isOnlineList: [],

      workflowServiceTypeList: [],

      options: regionData,

      // 查询
      tableData: [],
      selectForm: {
        orderNo: null,
        isPayOnline: null,
        goodsType: "service",
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],
      chooseOrder: null,

      //  详情
      detailDialog: false,
      detailInfo: null,

      //创建总跟踪单
      insertWorkflowDialog: false,
      insertWorkflowForm: {
        orderId: null,
        serviceType: null,
        customName: null,
        customNo: null,
        customAddress: null,
        customAddressCode: null,
        street: null
      }


    };
  },

  mounted() {
    this.load();
  },

  methods: {

    load() {

      listIsOnlineApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.isOnlineList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      listWorkflowServiceTypeApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.workflowServiceTypeList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


      getMyMerchantApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.merchantInfo = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      this.pageMerchantOrder();
    },


    handleChange(value) {
      this.insertWorkflowForm.customAddress = this.getCodeToText(value);
      console.log(value);//打印出城市code值
      ; //转换城市名称方法
    },
    //转换城市名称方法
    getCodeToText(val) {
      let cityNmae = "";
      switch (val.length) {
        case 1:
          cityNmae += codeToText[val[0]];
          break;
        case 2:
          cityNmae += codeToText[val[0]] + "/" + codeToText[val[1]];
          break;
        case 3:
          cityNmae +=
            codeToText[val[0]] +
            "/" +
            codeToText[val[1]] +
            "/" +
            codeToText[val[2]];
          break;
        default:
          break;
      }
      console.log(cityNmae);
      return cityNmae;
    },


    // 查询 begin
    selectReset() {
      this.selectForm = {
        orderNo: null,
        isPayOnline: null,
        goodsType: "service",
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageMerchantOrder(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageMerchantOrder(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageMerchantOrder(showMessage) {
      pageMerchantOrderApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },


    // 查询 end

    // 详情 begin
    detailShow(data) {
      this.detailInfo = data;
      this.detailDialog = true;
    },

    detailCancel() {
      this.detailDialog = false;
      this.detailInfo = null;
    },
    // 详情 end


    insertOrderSettlement(order) {
      if (!this.merchantInfo || !this.merchantInfo.merchantPublicAccountVO) {
        this.showMessage("请先前往 商户设置>对公账号 补充和检查对公账号信息", "error");
        return false;
      }
      let params = {
        "orderId": order.id
      };
      insertOrderSettlementApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageMerchantOrder();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },


    // 修改 begin

    // 新增总跟踪单 begin
    insertWorkflowShow(orderData) {
      this.chooseOrder = orderData;
      if (orderData && orderData.no) {
        let no = orderData.no;
        this.insertWorkflowForm.customNo = no;
      }

      if (orderData && orderData.id) {
        let id = orderData.id;
        this.insertWorkflowForm.orderId = id;
      }

        this.insertWorkflowDialog = true;
    },

    insertWorkflowCancel() {
      this.insertWorkflowDialog = false;
      this.insertWorkflowForm = {
        orderId: null,
        serviceType: null,
        customName: null,
        customNo: null,
        customAddress: null,
        customAddressCode: null,
        street: null
      };

    },


    insertWorkflowSubmit() {

      if (!this.insertWorkflowForm.serviceType) {
        this.showMessage("请选择服务类型", "error");
        return false;
      }
      if (!this.insertWorkflowForm.customName) {
        this.showMessage("请输入客户名称", "error");
        return false;
      }
      if (!this.insertWorkflowForm.customNo) {
        this.showMessage("请输入客户手机号码", "error");
        return false;
      }
      if (!this.insertWorkflowForm.customAddress) {
        this.showMessage("请选择地区", "error");
        return false;
      }
      if (!this.insertWorkflowForm.street) {
        this.showMessage("请填写详细街道地址", "error");
        return false;
      }

      insertWorkflowApi(this.insertWorkflowForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage("总跟踪单创建成功", "success");
              this.insertWorkflowCancel();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },
    // 新增 end

    // 新增总跟踪单 end


    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
