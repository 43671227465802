import Vue from "vue";


export function pageMerchantWorkflowApi(params, success) {
  let url = "/admin/web/workflow/pageMerchantWorkflow";
  Vue.prototype.alexPost(url, params, success);
}

export function insertWorkflowApi(params, success) {
  let url = "/admin/web/workflow/insertWorkflow";
  Vue.prototype.alexPost(url, params, success);
}


