<template>
  <div class="full-container">
    <div
      style="width: 20%;float: left;height: 70px;line-height: 70px;font-size: 0.9rem;color: darkgray;text-align: left;text-indent: 25px;">
      {{ currentDate }}{{ currentWeekDay }}
    </div>
    <div style="width: 80%;float: left">
      <div style="float: right;cursor: pointer; height: 70px;line-height: 70px;margin-right: 50px;">
        <el-dropdown @command="userMenuClick">
          <span class="el-dropdown-link">
            欢迎您
           <span
             style="color: #37b48ab3;font-weight: 600">{{ myMerchantInfo && myMerchantInfo.merchantDetailVO ? myMerchantInfo.merchantDetailVO.companyName : "" }}</span>
             &nbsp; {{ loginUser.no }}
            <span
              style="margin:0px 10px;font-size: 0.7rem;padding:5px;border-radius:10px;background-color: #37b48a;color: white;"
              v-if="myRoleList&&myRoleList.length>0" v-for="role in myRoleList">{{ role.name }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>个人中心</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="float: right;">
        <el-image style="width:auto;height: 50px;margin: 10px 10px;border-radius: 50px;border: 2px solid #37B48A"
                  :src="require('@/assets/home/head/user-logo.jpg')" />
      </div>
      <div style="float: right;">
        <el-dropdown>
          <span class="el-dropdown-link">
            <el-image style="width:auto;height: 30px;margin: 20px 10px;border-radius: 50px;"
                      :src="require('@/assets/home/head/ding.png')" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>暂无消息</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

  </div>
</template>
<script>
import { logoutApi } from "@/router/api/user-api";

import { listMyRoleApi } from "@/router/api/auth/role-api";
import { getMyMerchantApi } from "@/router/api/merchant/merchant-api";

export default {
  name: "NavMHead",
  data() {
    return {
      loginUser: JSON.parse(localStorage.getItem("user")),
      currentDate: "",
      currentWeekDay: "",

      myRoleList: [],
      myMerchantInfo: {}
    };
  },

  created() {
    this.listMyRole();
    this.getMyMerchant();

    this.updateDate();
    // 每分钟更新一次日期和星期
    setInterval(this.updateDate, 60000);
  },

  methods: {

    userMenuClick(command) {
      if ("logout" == command) {
        this.logout();
      }
    },

    logout() {
      let params = {
        token: localStorage.getItem("token")
      };
      logoutApi(params, res => {
        this.$router.push("/login");
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },

    listMyRole(showMessage) {
      let params = {};
      listMyRoleApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.myRoleList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    getMyMerchant(showMessage) {
      let params = {};
      getMyMerchantApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.myMerchantInfo = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    updateDate() {
      const now = new Date();
      this.currentDate = `${now.getFullYear()}年${(now.getMonth() + 1).toString().padStart(2, "0")}月${now.getDate().toString().padStart(2, "0")}` + "日";

      const weekDays = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      this.currentWeekDay = weekDays[now.getDay()];
    }
  }
};
</script>

<style scoped>

/deep/ .el-menu-item {
  color: black;
}

/deep/ .el-menu-item.is-active {
  background-color: #02A7F0;
}
</style>
