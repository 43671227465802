<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      商户首页
    </div>


    <div style="width:100%;height:auto;float:left;border-radius: 15px;padding-bottom:40px;">
      <!--   四个logo -->
      <div style="width: 100%;float: left;margin-bottom: 25px" v-if="orderCount">
        <div style="width:22.75%;float: left; ">
          <el-card class="box-card" style="cursor: pointer">
            <t-space align="center">
              <t-statistic title="今天订单量" :value="orderCount?orderCount.todayOrderCount+'':'0'" unit="单" trend="increase" color="red" />
              <t-icon name="task" class="icon"
                      style="font-size: 3rem;background-color:#E1F0EC;padding:10px;border-radius:100px;color: #37b48a" />
            </t-space>
          </el-card>
        </div>
        <div style="width:22.75%;float: left;margin-left: 3%; ">
          <el-card class="box-card" style="cursor: pointer">
            <t-space align="center">
              <t-statistic title="总订单量" :value="orderCount?orderCount.allOrderCount+'':'0'" unit="单" trend="increase" color="red" />
              <t-icon name="task" class="icon"
                      style="font-size: 3rem;background-color:#E1F0EC;padding:10px;border-radius:100px;color: #37b48a" />
            </t-space>
          </el-card>
        </div>
        <div style="width:22.75%;float: left; margin-left: 3%;">
          <el-card class="box-card" style="cursor: pointer">
            <t-space align="center">
              <t-statistic title="总成交额" :value="orderCount?orderCount.allPayMoney+'':'0.00'" unit="￥" trend="increase" color="red" />
              <t-icon name="money" class="icon"
                      style="font-size: 3rem;background-color:#E1F0EC;padding:10px;border-radius:100px;color: #37b48a" />
            </t-space>
          </el-card>
        </div>
        <div style="width:22.75%;float: left;margin-left: 3%; ">
          <el-card class="box-card" style="cursor: pointer">
            <t-space align="center">
              <t-statistic title="已提现" :value="0.00" unit="￥" trend="decrease" color="green" />
              <t-icon name="money" class="icon"
                      style="font-size: 3rem;background-color:#E1F0EC;padding:10px;border-radius:100px;color: #37b48a" />
            </t-space>
          </el-card>
        </div>
      </div>

      <!--    echarts -->
      <div
        style="width:74.25%;height:auto;float:left;border-radius: 15px;margin-right: 3%;background-color:white;padding: 20px;">
        <div ref="taskSumCharts" style="width: 100%;min-height: 560px;float: left;"></div>
      </div>

      <!--    echarts -->
      <div style="width:22.75%;float:left;height:600px;background-color:white;border-radius: 15px;padding: 15px">
        <div style="width:100%;height: 40px;line-height: 40px;float: left;">
          <div
            style="width:50%;height: 40px;line-height: 40px;float: left;text-align: left;font-size: 1rem;font-weight: 900;opacity: 0.8;">
            消息动态
          </div>
          <div
            style="width:50%;height: 40px;line-height: 40px;float: left;text-align: right;opacity: 0.8;font-size: 0.7rem;padding-right: 10px;">
            更多
          </div>
        </div>
        <div style="width: 100%;height: 560px;overflow-y: auto;">
          <div style="width: 100%;float:left;font-size: 0.7rem;height: 40px;line-height: 40px">
            <div style="width: 60%;float:left;text-align: left;">
              <el-tag type="success">新订单</el-tag>
              用户创建新订单
            </div>
            <div style="width: 40%;float:left;text-align: right;color: gray;opacity: 0.7;">
              2024-02-50 13:30:30
            </div>


          </div>
        </div>
      </div>


    </div>


  </div>
</template>

<script>
import * as echarts from "echarts";
import { countMerchantOrderApi } from "@/router/api/order/order-api";
import { getAlexBoolListApi } from "@/router/api/common/data-api";

export default {
  name: "MerchantCharts",
  data() {
    return {
      orderCount:null,
      taskSumCharts: null
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {

      countMerchantOrderApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.orderCount = res.data.resultSetData;
              console.info(this.orderCount);
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      this.listMyOrderMoney();

    },

    listMyOrderMoney() {
      this.taskSumCharts = echarts.init(this.$refs.taskSumCharts);
      //TODO 数据库查询数据
      this.loadMyOrderMoneyChants();

    },

    loadMyOrderMoneyChants() {
      let option = {

        color: ["#E1F0EC"],
        title: {
          text: "本月订单量"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        legend: {
          data: ["订单曲线"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["1", "2", "3", "4", "5", "6", "7"]
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "订单量",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#37b48a"
                },
                {
                  offset: 1,
                  color: "#E1F0EC"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: [140, 232, 101, 264, 90, 340, 250]
          }

        ]
      };
      this.taskSumCharts.clear();
      this.taskSumCharts.setOption(option);
    },


    // 工具


    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }


  }

};

</script>


<style scoped>


</style>
