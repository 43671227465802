<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      商户
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">商户号：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable  prefix-icon="el-icon-search" v-model="selectForm.merchantNo"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">企业名称：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable  prefix-icon="el-icon-search" v-model="selectForm.companyName"></el-input>
          </div>

          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary"
                       @click="pageMerchant(true)">搜索
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="selectReset()">重置
            </el-button>
          </div>
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">

<!--            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">-->
<!--              <a-icon type="plus-circle" />-->
<!--              新增菜单-->
<!--            </el-button>-->

<!--            <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消" @confirm="deleteMenuBatch()">-->
<!--              <el-button size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"-->
<!--                         type="primary">-->
<!--                <a-icon type="minus-circle" />-->
<!--                批量删除-->
<!--              </el-button>-->
<!--            </a-popconfirm>-->


          </div>
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" height="600" :data="tableData" style="color: #555;" size="mini">
            <el-table-column align="center" prop="merchantNo" label="商户号" min-width="20%"> </el-table-column>
            <el-table-column align="center" sortable prop="merchantDetailVO.companyName"  label="商户名称" min-width="20%" style="color: red"></el-table-column>
            <el-table-column align="center" sortable prop="merchantDetailVO.isWorkflowStr"  label="具备流程服务" min-width="20%" style="color: red"></el-table-column>
            <el-table-column align="center" sortable prop="merchantDetailVO.companyIdCard"  label="统一社会信用代码" min-width="20%" style="color: red"></el-table-column>
            <el-table-column align="center" sortable prop="merchantDetailVO.chargeUserName"  label="负责人名称" min-width="20%" style="color: red"></el-table-column>
            <el-table-column align="center" sortable prop="merchantDetailVO.chargeUserTel"  label="负责人手机号码" min-width="20%" style="color: red"></el-table-column>
            <el-table-column align="center"  label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.merchantDetailVO.createDate) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">
                <span style="font-size:1rem;color:#25AD7F;cursor: pointer;"  @click="infoShow(scope.row)">商户资料</span> &nbsp;
              </template>
            </el-table-column>

          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="商户资料" :visible.sync="infoDialog" width="40%" :before-close="infoCancel">
      <div style="width: 100%;float: left">
        <div style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"  v-if="infoData&&infoData.merchantDetailVO">
          <el-descriptions title="主体信息" :column=2>
            <el-descriptions-item label="企业名称" >{{ infoData.merchantDetailVO.companyName }}</el-descriptions-item>
            <el-descriptions-item label="企业地址">{{ infoData.merchantDetailVO.companyAddress }}</el-descriptions-item>
            <el-descriptions-item label="统一社会信用代码" >{{ infoData.merchantDetailVO.companyIdCard }}</el-descriptions-item>
            <el-descriptions-item label="具备流程服务" >{{ infoData.merchantDetailVO.isWorkflowStr }}</el-descriptions-item>
            <el-descriptions-item label="营业执照" >
              <el-image v-if="infoData.merchantDetailVO.companyIdCardGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+infoData.merchantDetailVO.companyIdCardGuid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+infoData.merchantDetailVO.companyIdCardGuid]">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="负责人" :column=2>
            <el-descriptions-item label="姓名">{{ infoData.merchantDetailVO.chargeUserName }}</el-descriptions-item>
            <el-descriptions-item label="身份证号码">{{ infoData.merchantDetailVO.chargeUserIdCard }}</el-descriptions-item>
            <el-descriptions-item label="手机号码">{{ infoData.merchantDetailVO.chargeUserTel }}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{ infoData.merchantDetailVO.chargeUserMail }}</el-descriptions-item>
            <el-descriptions-item label="身份证（正反面）">
              <el-image v-if="infoData.merchantDetailVO.chargeUserIdCardGuid" v-for="guid in infoData.merchantDetailVO.chargeUserIdCardGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+guid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="infoCancel">关闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import { pageMerchantApi } from "@/router/api/merchant/merchant-api";




export default {
  name: "MerchantIndex",
  data() {
    return {

      //查询
      tableData:[],
      selectForm: {
        merchantNo: null,
        companyName: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },

      infoDialog:false,
      infoData:{},
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.pageMerchant();
    },

    // 查询
    selectReset(){
      this.selectForm = {
        merchantNo: null,
        companyName: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      }
      this.pageMerchant(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageMerchant(true);
    },

    pageMerchant(showMessage){
      pageMerchantApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;

            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    infoShow(data) {
      this.infoDialog = true;
      this.infoData = data;
    },

    infoCancel() {
      this.infoDialog = false;
      this.infoData = {};
    },

    // 工具

    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }



  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
