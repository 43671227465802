import Vue from "vue";

export function pageAllOrderApi(params, success) {
  let url = "/admin/web/order/pageAllOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantOrderApi(params, success) {
  let url = "/admin/web/order/pageMerchantOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function updateOrderTransformNoApi(params, success) {
  let url = "/admin/web/order/updateOrderTransformNo";
  Vue.prototype.alexPost(url, params, success);
}



export function countMerchantOrderApi(params, success) {
  let url = "/admin/web/order/countMerchantOrder";
  Vue.prototype.alexPost(url, params, success);
}


