import Vue from "vue";

export function insertOrderSettlementApi(params, success) {
  let url = "/admin/web/orderSettlement/insertOrderSettlement";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantOrderSettlementApi(params, success) {
  let url = "/admin/web/orderSettlement/pageMerchantOrderSettlement";
  Vue.prototype.alexPost(url, params, success);
}



