<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      订单>农产品订单
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 左边查询区 -->
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">订单号：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.orderNo"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">支付方式：</label>
            <el-select size="mini" style="width:100px;float: left;" v-model="selectForm.isPayOnline" clearable
                       filterable>
              <el-option v-for="item in isOnlineList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary"
                       @click="pageMerchantOrder(true)">搜索
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="selectReset()">重置
            </el-button>
          </div>

        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" @selection-change="handleSelectionChange" height="600" :data="tableData"
                    style="color: #555;" size="mini">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column sortable align="center" prop="orderNo" label="订单号" min-width="20%"></el-table-column>
            <el-table-column align="center"  prop="no" label="买家" min-width="20%">
            </el-table-column>
            <el-table-column align="center" prop="productMoney" label="商品价格" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="payNumber" label="购买数量" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="payMoney" label="用户支付" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="isPayOnlineStr" label="支付方式" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="isPayStr" label="已支付" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="isOnlineStr" label="渠道" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="statusStr" label="订单状态" min-width="20%"></el-table-column>
            <el-table-column align="center" label="商户" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.merchantVO && scope.row.merchantVO.merchantDetailVO ? scope.row.merchantVO.merchantDetailVO.companyName : ""
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="productName" label="产品" min-width="20%">
            </el-table-column>

            <el-table-column align="center" prop="transformNo" label="运单号" min-width="20%"></el-table-column>

            <el-table-column align="center" label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate ? scope.row.createDate : "") }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="40%">
              <template slot-scope="scope">
                <span style="font-size:0.9rem;color:#25AD7F;cursor: pointer;"
                      @click="detailShow(scope.row)">详情</span>
                <span style="margin:0px 5px">|</span>
                <span style="font-size:0.9rem;cursor: pointer;color: #409EFF"
                      @click="updateShow(scope.row)">物流运单</span>
                <span style="margin:0px 5px">|</span>
                <span v-if="scope.row.status=='complete'"
                      style="font-size:0.9rem;cursor: pointer;color: #409EFF" @click="insertOrderSettlement(scope.row)">申请提现</span>
                <span v-if="scope.row.status!='complete'"
                      style="font-size:0.9rem;cursor: pointer;color: gray;cursor: not-allowed;">申请提现</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>


    <!-- 修改 -->
    <el-dialog title="物流单号" :visible.sync="updateDialog" width="40%" :before-close="updateCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">

          <el-form-item style="">
            <template slot="label">
              订单号 <span style="color: red">*</span>
            </template>
            <el-input size="mini" :disabled="true" style="width:85%;float: left;" v-model="updateForm.orderNo"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              运单号 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.transformNo"
                      placeholder="物流单号"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              物流公司 <span style="color: red">*</span>
            </template>

            <el-select size="mini" style="width:100px;float: left;" v-model="updateForm.transformType" clearable
                       filterable>
              <el-option v-for="item in companyTypeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>

            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="订单详情" :visible.sync="detailDialog" width="40%" :before-close="detailCancel">
      <div style="width: 100%;float: left">
        <div
          style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"
          v-if="detailInfo">
          <el-descriptions title="订单" :column=2>
            <el-descriptions-item label="订单号">{{ detailInfo.orderNo ? detailInfo.orderNo : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="运单号">{{ detailInfo.transformNo ? detailInfo.transformNo : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="渠道">{{ detailInfo.isOnlineStr ? detailInfo.isOnlineStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="订单状态">{{ detailInfo.statusStr ? detailInfo.statusStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="支付方式">{{ detailInfo.isPayOnlineStr ? detailInfo.isPayOnlineStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="是否支付">{{ detailInfo.isPayStr ? detailInfo.isPayStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="购买数量">{{ detailInfo.payNumber ? detailInfo.payNumber : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="支付金额">{{ detailInfo.payMoney ? detailInfo.payMoney : "" }}元
            </el-descriptions-item>
            <el-descriptions-item label="创建时间" :span="2">
              {{ parseDate(detailInfo.createDate ? detailInfo.createDate : "")
              }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="微信用户" :column=2>
            <el-descriptions-item label="账号">{{ detailInfo.userVO ? detailInfo.userVO.no : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="名称">{{ detailInfo.userVO ? detailInfo.userVO.name : "" }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="订单地址" :column=2>

            <el-descriptions-item label="收件人姓名">
              {{ detailInfo.toOrderAddressVO ? detailInfo.toOrderAddressVO.userName : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="收件人联系方式">
              {{ detailInfo.toOrderAddressVO ? detailInfo.toOrderAddressVO.userTel : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="收件人地址">
              {{ detailInfo.toOrderAddressVO ? detailInfo.toOrderAddressVO.fullAddress : "" }}
            </el-descriptions-item>

          </el-descriptions>
          <el-descriptions title="产品" :column=2>


            <el-descriptions-item label="类型">{{ detailInfo.productVO.goodsTypeStr ? detailInfo.productVO.goodsTypeStr : ''}}
            </el-descriptions-item>

            <el-descriptions-item  label="对应类型">{{ detailInfo.productVO.productTypeStr ? detailInfo.productVO.productTypeStr :'' }}{{ detailInfo.productVO.workflowTypeStr ? detailInfo.productVO.workflowTypeStr : ''}}
            </el-descriptions-item>

            <el-descriptions-item label="标题">{{ detailInfo.productVO.title ? detailInfo.productVO.title : "0" }}
            </el-descriptions-item>
            <el-descriptions-item label="价格">{{ detailInfo.productVO.price ? detailInfo.productVO.price : "0" }}元
            </el-descriptions-item>

            <el-descriptions-item label="特色">{{ detailInfo.productVO.feature ? detailInfo.productVO.feature : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="产品性能">{{ detailInfo.productVO.context ? detailInfo.productVO.context : "0"
              }}
            </el-descriptions-item>

            <el-descriptions-item label="创建时间" :span="2">
              {{ parseDate(detailInfo.createDate ? detailInfo.createDate : "")
              }}
            </el-descriptions-item>
            <el-descriptions-item label="缩略图" :span="2">
              <el-image v-if="detailInfo.productVO.mainImageGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+detailInfo.productVO.mainImageGuid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+detailInfo.productVO.mainImageGuid]">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item label="详细照片" :span="2">
              <div style="width: 100%;float: left;" v-if="detailInfo.productVO.detailImageGuid"
                   v-for="guid in detailInfo.productVO.detailImageGuid">
                <el-image title="点击放大"
                          style="width: 100px; height: auto"
                          :src="'/admin/web/file/downloadFile?id='+guid"
                          :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
                </el-image>
                <br />
              </div>

            </el-descriptions-item>
          </el-descriptions>
        </div>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="detailCancel">关闭</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>


import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { listIsOnlineApi,listExpressCompanyApi } from "@/router/api/common/data-api";
import { pageMerchantOrderApi, updateOrderTransformNoApi } from "@/router/api/order/order-api";
import { getMyMerchantApi } from "@/router/api/merchant/merchant-api";

import { insertOrderSettlementApi } from "@/router/api/order/order-settlement-api";

export default {
  name: "MerchantOrder",
  data() {
    return {
      merchantInfo: {},
      isOnlineList: [],

      companyTypeList:[],

      // 查询
      tableData: [],
      selectForm: {
        orderNo: null,
        isPayOnline: null,
        goodsType: "product",
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],

      //修改
      updateDialog: false,
      updateForm: {
        id: null,
        orderNo: null,
        transformNo: null,
        transformType: null
      },

      //  详情
      detailDialog: false,
      detailInfo: null


    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {

      listIsOnlineApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.isOnlineList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      listExpressCompanyApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.companyTypeList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      getMyMerchantApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.merchantInfo = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      this.pageMerchantOrder();
    },


    // 查询 begin
    selectReset() {
      this.selectForm = {
        orderNo: null,
        isPayOnline: null,
        goodsType: "product",
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageMerchantOrder(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageMerchantOrder(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageMerchantOrder(showMessage) {
      pageMerchantOrderApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    exportTable() {
      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要导出的数据", "warning");
        return false;
      }

      const data = [
        [
          "名称",
          "标题",
          "规格",
          "配料",
          "特色",
          "产品性能",
          "作物（或范围）",
          "防治对象",
          "制剂用药量",
          "使用方法",
          "注意事项",
          "价格",
          "是否上架"
        ]
      ];

      //添加数据

      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          let cell1 = row.name ? row.name : "";
          let cell2 = row.title ? row.title : "";
          let cell3 = row.specification ? row.specification : "";
          let cell4 = row.proportioning ? row.proportioning : "";
          let cell5 = row.feature ? row.feature : "";
          let cell6 = row.context ? row.context : "";
          let cell7 = row.crop ? row.crop : "";
          let cell8 = row.control ? row.control : "";
          let cell9 = row.useDose ? row.useDose : "";
          let cell10 = row.useMethod ? row.useMethod : "";
          let cell11 = row.attention ? row.attention : "";
          let cell12 = row.price ? row.price : "";
          let cell13 = row.isShelf ? row.isShelf : "";

          data.push([cell1, cell2, cell3, cell4, cell5, cell6, cell7, cell8, cell9, cell10, cell11, cell12, cell13]);
        }
      }
      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // 使用blob和FileReader创建一个Blob URL
      const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      const blobUrl = window.URL.createObjectURL(dataBlob);

      // 使用saveAs下载文件
      saveAs(dataBlob, "商户用户-" + new Date().toLocaleString() + ".xlsx");

      // 清理
      window.URL.revokeObjectURL(blobUrl);
    },

    // 查询 end

    // 详情 begin
    detailShow(data) {
      this.detailInfo = data;
      this.detailDialog = true;
    },

    detailCancel() {
      this.detailDialog = false;
      this.detailInfo = null;
    },
    // 详情 end


    insertOrderSettlement(order) {
      if (!this.merchantInfo || !this.merchantInfo.merchantPublicAccountVO) {
        this.showMessage("请先前往 商户设置>对公账号 补充和检查对公账号信息", "error");
        return false;
      }
      let params = {
        "orderId": order.id
      };
      insertOrderSettlementApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageMerchantOrder();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },


    // 修改 begin


    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        orderNo: data.orderNo,
        transformNo: data.transformNo,
        transformType: data.transformType,
      };
    },


    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,
        orderNo: null,
        transformNo: null,
        transformType: null
      };
    },

    updateSubmit() {
      if (!this.updateForm.transformNo) {
        this.showMessage("请填写物流单号", "error");
        return false;
      }
      if (!this.updateForm.transformType) {
        this.showMessage("请选择物流公司", "error");
        return false;
      }

      updateOrderTransformNoApi(this.updateForm, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageMerchantOrder();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 修改 end

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
